@import url('https://fonts.googleapis.com/css2?family=Oxanium:wght@200;300;400;500;600;700;800&display=swap');

* {
  margin: 0;
  padding: 0;
}
html {
  overflow-x: hidden;
}
body {
  font-family: 'Oxanium', sans-serif;
  overflow-x: hidden;
}
a {
  text-decoration: none;
  color: inherit;
}
ul {
  list-style-type: none;
}
/* #viewport {
  background: #000;
} */

.swal-title {
  color: #000 !important;
  font-size: 28px !important;
  font-weight: 700 !important;
  line-height: 28px !important;
}
div:where(.swal2-container) .swal2-html-container {
  text-transform: capitalize !important;
}
.swal-confirm-btn {
  color: #fff !important;
  text-align: center !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 21px !important;
  padding: 11px 16px !important;
  border-radius: 8px !important;
  border: 1px solid #000 !important;
  background: #3e8dff !important;
  box-shadow: 2px 2px 0px 0px #000 !important;
}

.slick-prev,
.slick-next {
  z-index: -100;
}

.__floater__open {
  filter: none !important;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 5px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: gray; /* color of the scroll thumb */
  border-radius: 5px; /* roundness of the scroll thumb */
  border: 1px solid gray; /* creates padding around scroll thumb */
}
