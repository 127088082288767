.navigation {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0rem;
  background-color: #fff;
  color: black;
  position: fixed;
  top: 0;
  background: transparent;
  z-index: 100;
  background: black;
}
.brand-name {
  text-decoration: none;
  color: black;
  width: 40px;
  margin-left: 1rem;
}
.navigation-menu {
  margin-left: auto;
}
.navigation-menu ul {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  background: black;
}
.navigation-menu li {
  list-style-type: none;
  margin: 0 1rem;
}
.navigation-menu li a {
  font-size: 18px;
  font-weight: 700;
  color: white;
  display: block;
  width: 100%;
  transition: all 1.5s ease;
  text-decoration: none;
  text-underline-offset: 8px;
  text-decoration-color: #ff3723;
  text-decoration-thickness: 2px;
}
.navigation-menu li a:hover {
  text-decoration: underline;
  text-decoration-color: #ff3723;
  text-decoration-thickness: 2px;
}
.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #ff3723;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}
.hamburger:hover {
  background-color: #ff3723;
}
.hamburger .fa-bars {
  color: #fff;
}
.winners_button {
  background-color: #d80000;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  display: flex;
  padding: 7px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: none;
  outline: none;
  margin: 0 auto;
}

.logout_button {
  background-color: #d80000;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  display: flex;
  padding: 7px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: none;
  outline: none;
  margin: 0 auto;
}

@media screen and (max-width: 1100px) {
  .navigation-menu li {
    margin: 0 10px;
  }
  .navigation-menu li a {
    font-size: 13px;
  }
  .winners_button {
    /* font-size: 13px; */
    padding: 10px 20px;
  }
  .logout_button {
    /* font-size: 13px; */
    padding: 10px 20px;
  }
}

@media screen and (max-width: 768px) {
  .hamburger {
    display: block;
  }
  .navigation-menu ul {
    position: absolute;
    top: 100px;
    left: 0;
    flex-direction: column;
    width: 100%;
    display: none;
  }
  .navigation-menu.expanded ul {
    display: block;
  }
  .navigation-menu li {
    text-align: center;
    margin: 0;
  }
  .navigation-menu li a {
    width: 100%;
    padding: 10px 0;
    font-weight: 500;
    font-size: 20px;
  }
  .logout_button {
    margin: 10px auto;
    display: flex;
    /* font-weight: 500; */
  }

  .mobile-winner-button{
    display: inline-flex;
    position: absolute;
    top: 26px;
    right: 75px;
  }

  .mobile-winner-button .fa-trophy {
    font-size: 26px; /* Adjust the font size as per your requirement */
    color:#d80000;
  } 

  .tabletdesktop-winner-button{
    display: none;
  }

  .mobile-android-button {
    display: inline-flex;
    position: absolute;
    top: 20px;
    right: 140px;
  }
  .mobile-android-button .fa-android {
    font-size: 34px; /* Adjust the font size as per your requirement */
    color:#d80000;
  } 

  .winners_button {
    background: black;
  }
  .tabletdesktop-logout-button .fa-sign-out{
    margin-bottom: 2px;
  }
}

@media (min-width: 768px) {
  .mobile-icons{
    display: none;
  }
  .mobile-category {
    display: none;
  }
  .tabletdesktop-winner-button{
    display: flex;
  }

  .tabletdesktop-winner-button .fa-android{
    font-size: 23px;
    margin-bottom: 3px;
  }

  .tabletdesktop-winner-button .fa-trophy{
    margin-bottom: 3px;
  }

  .tabletdesktop-winner-button .fa-sign-out{
    margin-bottom: 3px;
  }

  .tabletdesktop-logout-button .fa-sign-out{
    margin-bottom: 2px;
  }
}
