.games_page .intro_part {
  background: linear-gradient(
      90deg,
      #000 0%,
      #000 27.56%,
      rgba(0, 0, 0, 0) 53.85%
    ),
    linear-gradient(180deg, rgba(0, 0, 0, 0.72) 0%, rgba(0, 0, 0, 0) 100%),
    url("/public/images/war_soldiers_shooting.png") no-repeat;
  background-size: cover;
  padding: 20px 0;
}
.games_page .intro_part .intro_text_container {
  margin-top: 100px;
}
.games_page .intro_part .intro_text_container h6 {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
}
.games_page .intro_part .intro_text_container h2 {
  color: #fff;
  font-size: 80px;
  font-weight: 700;
  margin-bottom: 6px;
}
.games_page .intro_part .intro_text_container h2 span {
  color: #d80000;
}
.games_page .intro_part .intro_text_container p {
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 40px;
}
.games_page .intro_part .intro_text_container button {
  border-radius: 10px;
  background: linear-gradient(97deg, #f5c968 0%, #ab6132 94.73%);
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  padding: 10px 20px;
  border: none;
  outline: none;
}

.games_page .games_wrapper {
  background: url("/public/images/all_games_background.png") no-repeat;
  background-size: cover;
  padding: 20px 0;
}
.games_page .games_wrapper h4 {
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.games_page .games_wrapper .game_box {
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease;
  border-radius: 0px 0px 10px 10px;
  margin-bottom: 20px;
  box-shadow: 0 10px 25px 10px rgba(0,0,0,.3);
}
.games_page .games_wrapper .game_box .game_top_container {
  position: relative;
  overflow: hidden;
}
.games_page .games_wrapper .game_box .game_top_container .game_top_link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 0px 0px;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transform: translate(0, -100%);
  transition: all 0.5s ease;
}
.games_page .games_wrapper .game_box .game_top_container .game_top_link button {
  background: #ff3723;
  color: #fff;
  border-radius: 10px;
  font-weight: 700;
  font-size: 14px;
  border: none;
  outline: none;
  padding: 10px 20px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.games_page .games_wrapper .game_box .game_top_container img {
  width: 100%;
  border-radius: 10px 10px 0px 0px;
}
.games_page .games_wrapper .game_box:hover {
  box-shadow: 0 10px 50px rgb(0 0 0 / 15%);
}
.games_page .games_wrapper .game_box:hover .game_top_container .game_top_link {
  transform: translate(0, 0);
}
.games_page .games_wrapper .game_box .game_detail {
  padding: 20px;
  background: #fff;
}
.games_page .games_wrapper .game_box .game_detail .game_title_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.games_page .games_wrapper .game_box .game_detail .game_title_box .game_title {
  font-weight: 700;
  font-size: 22px;
  color: #000;
}
.games_page .games_wrapper .game_box .game_detail .game_description {
  color: #000;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}
.games_page .brand_list.brand_list_footer {
  background: #000;
  padding: 10px 0;
}
.games_page .footer_list {
  background: #d80000;
  padding: 20px 0;
}
.games_page .footer_list .footer_container {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}

/* Media query for tablets and desktops */
@media (max-width: 768px) {
  .games_page .intro_part {
    background: linear-gradient(
        90deg,
        #000 0%,
        #000 27.56%,
        rgba(0, 0, 0, 0) 53.85%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0.72) 0%, rgba(0, 0, 0, 0) 100%),
      url("/public/images/mobilelandingpage1.png") no-repeat;
    background-size: cover;
  }
  .games_page .intro_part .intro_text_container h2 {
    font-size: 50px;
    text-align: center;
  }
  .games_page .intro_part .intro_text_container {
    text-align: center;
  }
  .games_page .intro_part .intro_text_container button {
    margin: 0 auto;
  }
  .games_page .intro_part .intro_text_container p {
    font-size: 18px;
    text-align: center;
  }
}



