.swiper_element .swiper_top_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.swiper_element .swiper_top_flex h4 {
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0;
}
.swiper_element .swiper_top_flex button {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 10px;
  background: linear-gradient(97deg, #f5c968 0%, #ab6132 94.73%);
  border: none;
  outline: none;
  padding: 10px 20px;
}

/* .swiper_element .swiper_card {
  border-radius: 10px;
}
.swiper_element .swiper_card img {
  width: 100%;
  border-radius: 10px 10px 0px 0px;
} */
.swiper_element .swiper_card .swiper_card_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0px 0px 10px 10px;
  background: #d80000;
  color: #fff;
  padding: 10px 15px;
}
.swiper_element .swiper_card .swiper_card_title .swiper_card_text {
  color: #fff;
  font-size: 15px;
  font-weight: 700;
}
.swiper_element
  .swiper_card
  .swiper_card_title
  .swiper_card_arrow
  .fa-arrow-right {
  color: #fff;
  font-size: 17px;
  font-weight: 700;
}

.swiper_element .swiper_nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  float: right;
  margin-top: 20px;
  margin-bottom: 40px;
}
.swiper_element .swiper_nav .swiper_nav_btn {
  background: transparent;
  width: 40px;
  height: 40px;
  border: 1px solid #fff;
  outline: none;
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
}


.swiper_element .swiper_card {
  border-radius: 10px;
  box-shadow: 0 10px 25px 10px rgba(0,0,0,.3);
}
.swiper_element .swiper_card img {
  width: 100%;
  border-radius: 10px 10px 0px 0px;
}

/* .swiper_element .swiper-image {
  width: 267px; 
  height: 130.37px; 
  overflow: hidden; 
  position: relative; 
}

.swiper_element .swiper-image img {
    width: 100%; 
    height: 100%; 
    object-fit: cover;  
    border-radius: 10px 10px 0px 0px;
} */

/* @media (max-width: 768px){
  .swiper_element .swiper-image {
    width: auto; 
    height: 190.43px; 
    overflow: hidden; 
    position: relative; 
  }
  
  .swiper_element .swiper-image img {
      width: 100%; 
      height: 100%; 
      object-fit: cover;  
      border-radius: 10px 10px 0px 0px !important;
  }
} */