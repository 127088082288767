.slick-slide img {
    display: inline;
    width: fit-content !important;
    margin: auto;
}
  /* Mobile-specific CSS rules */
  @media (max-width: 991px) {
    .slick-slide img {
      display: inline; 
      padding: 0;
      max-width: 100% !important;
      margin: 0 20px;
    }
  }


.slick-slide div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.brand_list_footer div{
  height: 100px;
}
  
