.home_page .intro_part {
  background: linear-gradient(
      90deg,
      #000 0%,
      #000 27.56%,
      rgba(0, 0, 0, 0) 53.85%
    ),
    linear-gradient(180deg, rgba(0, 0, 0, 0.72) 0%, rgba(0, 0, 0, 0) 100%),
    url("/public/images/war_soldiers_shooting.png") no-repeat;
  background-size: cover;
  padding: 20px 0;
}

.home_page .intro_part .intro_text_container {
  margin-top: 100px;
}
.home_page .intro_part .intro_text_container h6 {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
}
.home_page .intro_part .intro_text_container h2 {
  color: #fff;
  font-size: 80px;
  font-weight: 700;
  margin-bottom: 6px;
}
.home_page .intro_part .intro_text_container h2 span {
  color: #d80000;
}
.home_page .intro_part .intro_text_container p {
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 40px;
}
.home_page .intro_part .intro_text_container button {
  border-radius: 10px;
  background: linear-gradient(97deg, #f5c968 0%, #ab6132 94.73%);
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  padding: 10px 20px;
  border: none;
  outline: none;
}

.home_page .brand_list {
  background: #d80000;
  padding: 10px 0;
}
.home_page .brand_list p {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}
.home_page .brand_list .brand_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home_page .brand_list .brand_container .brand_img img {
  width: 100%;
  max-width: 150px;
}

.home_page .brand_list.brand_list_footer {
  background: #000;
  padding: 10px 0;
}

.home_page .games_1 {
  background: url("/public/images/soldiers_mask_group.png") no-repeat;
  background-size: cover;
  padding: 20px 0 0 0;
}

.home_page .games_10 {
  /* background: url("/public/images/soldiers_mask_group.png") no-repeat; */
  background-size: cover;
  /* padding: 20px 0; */
}

.home_page .games_2 {
  background: url("/public/images/city_doom.png") no-repeat;
  background-size: cover;
  padding: 20px 0 0 0;
}

.home_page .games_3 {
  background: url("/public/images/sky_stars_1.png") no-repeat;
  background-size: cover;
  padding: 20px 0 0 0;
}

.home_page .games_4 {
  background: url("/public/images/sky_stars_2.png") no-repeat;
  background-size: cover;
  padding: 20px 0 0 0;
}

.home_page .footer_list {
  background: #d80000;
  padding: 20px 0;
}
.home_page .footer_list .footer_container {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}

.home_page .top_games {
  background: url("/public/images/war_tank.png") no-repeat;
  background-size: cover;
  padding: 20px 0;
}
.home_page .top_games h4 {
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 40px;
  text-transform: uppercase;
}
.home_page .top_games h4 span {
  color: #d80000;
}
.home_page .top_games .top_game_wrapper {
  margin-bottom: 20px;
}
.home_page .top_games .top_game_wrapper .top_games_img {
  margin-bottom: 12px;
}
.home_page .top_games .top_game_wrapper .top_games_img img {
  width: 100%;
  max-width: 100px;
}
.home_page .top_games .top_game_wrapper .top_game_title {
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
}
.home_page .just_gamer_img img {
  width: 100%;
  max-width: 200px;
}

.home_page .uv_played {
  background: url("/public/images/uv_played_back.png") no-repeat;
  background-size: cover;
}
.home_page .uv_played .uv_played_img img {
  width: 100%;
}
.home_page .uv_played .uv_played_text .uv_played_title {
  color: #fff;
  font-size: 50px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.home_page .uv_played .uv_played_text .uv_played_btn {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 10px;
  background: #d80000;
  padding: 10px 20px;
  border: none;
  outline: none;
}

/* Media Queries */
@media screen and (max-width: 992px) {
  .home_page .top_games .top_game_wrapper .top_games_img {
    text-align: center;
  }
  .home_page .just_gamer_img {
    text-align: center;
  }

  .home_page .uv_played .uv_played_text .uv_played_title {
    font-size: 40px;
    line-height: 40px;
  }
}
@media screen and (max-width: 768px) {
  .home_page .brand_list p {
    text-align: center;
  }
  .home_page .brand_list .brand_container {
    flex-direction: column;
    gap: 10px;
  }

  .home_page .top_games .top_game_wrapper .top_game_title {
    text-align: center;
  }

  .home_page .uv_played .uv_played_text {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
}

/* Media query for tablets and desktops */
@media (max-width: 768px) {
  .home_page .intro_part {
    background: linear-gradient(
        90deg,
        #000 0%,
        #000 27.56%,
        rgba(0, 0, 0, 0) 53.85%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0.72) 0%, rgba(0, 0, 0, 0) 100%),
      url("/public/images/mobilelandingpage1.png") no-repeat;
    background-size: cover;
    padding: 20px 0;
  }
}

.line-image {
  margin-top: -60px;
}

@media (max-width: 768px) {
  .home_page .intro_part .intro_text_container p {
    font-size: 18px;
    text-align: center;
  }
}

/**************************** spinner section **********************/

.just-gamers-circle-shape img {
  /* position: absolute; */
  /* bottom: 112px;
  left: 145px; */
  z-index: 2;
}

.just-gamers-circle-shape img:nth-child(1) {
  position: relative;
  top: -227px;
  right: -93px;
  width: 198px;
  height: 124px;
  z-index: 1;
  /* background-color: red; */
}
.just-gamers-circle-shape img:nth-child(2) {
  position: relative;
  top: -171px;
  right: -14px;
  width: 146px;
  height: 146px;
  /* background-color: red; */
}

.just_gamer_img img {
  max-height: 445.5px;
}

@media screen and (max-width: 1152px) {
  .just-gamers-circle-shape img:nth-child(1) {
    position: relative;
    top: -138px !important;
    right: -121px !important;
    width: 88px !important;
    height: 60px !important;
    z-index: 1;
  }

  .just-gamers-circle-shape img:nth-child(2) {
    position: relative;
    top: -110px !important;
    right: -74px !important;
    width: 90px !important;
    height: 90px !important;
  }
}

@media screen and (max-width: 1024px) {
  .just-gamers-circle-shape img:nth-child(1) {
    position: relative;
    top: -199px;
    right: -120px;
    width: 99px;
    height: 63px;
    z-index: 1;
  }

  .just-gamers-circle-shape img:nth-child(2) {
    position: relative;
    top: -170px;
    right: -44px;
    width: 146px;
    height: 146px;
  }
}

@media (max-width: 768px) {
  .home_page .intro_part .intro_text_container h2 {
    font-size: 50px;
    text-align: center;
  }
  .home_page .intro_part .intro_text_container {
    text-align: center;
  }
  .home_page .intro_part .intro_text_container button {
    margin: 0 auto;
  }
}

@media screen and (max-width: 991px) {
  .just-gamers-circle-shape img:nth-child(1) {
    position: relative;
    top: -227px;
    right: -161px;
    width: 198px;
    height: 124px;
    z-index: 1;
  }
  .just-gamers-circle-shape img:nth-child(2) {
    position: relative;
    top: -171px;
    right: -81px;
    width: 146px;
    height: 146px;
    /* background-color: red; */
  }
}

@media screen and (max-width: 767px) {
  .just-gamers-circle-shape img:nth-child(1) {
    position: relative;
    top: -140px !important;
    right: -113px !important;
    width: 88px !important;
    height: 60px !important;
    z-index: 1;
  }

  .just-gamers-circle-shape img:nth-child(2) {
    position: relative;
    top: -112px !important;
    right: -65px !important;
    width: 90px !important;
    height: 90px !important;
  }
}

.top-gamer-main-image {
  max-height: 445.5px !important;
}

.rotateme {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 50s;
  animation-duration: 50s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
@keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

/**************************** spinner section **********************/
